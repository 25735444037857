import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { getPlzLocation } from "@/lib/pdfPrinter/UserPdf";

export const printClientPdf = (client, contactUser) => {
	const doc = new jsPDF()
	const highlightedLines = []
	const { first_name, last_name, birth_date, birth_location, statutory_basis, entry, license, exit, persons, custody, administrative_district, socialWorker,
		school, group } = client.attributes
	const clientName = `${first_name} ${last_name}`
	const birthDate = birth_date ? birth_date.toLocaleDateString("de-DE", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit"
	}) : ''
	const birthLocation = birth_location ? birth_location : ''
	const statBasis = statutory_basis ? statutory_basis.attributes.name : ''
	const entryDate = entry ? entry.toLocaleDateString("de-DE", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit"
	}) : ''
	const licenseDate = license ? license.toLocaleDateString("de-DE", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit"
	}) : ''
	const exitDate = exit ? exit.toLocaleDateString("de-DE", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit"
	}) : ''
	const district = administrative_district ? administrative_district.attributes.name : ""
	const districtStreet = administrative_district ? administrative_district.attributes.street : ""
	const districtPlz = administrative_district ? administrative_district.attributes.plz : ""
	const districtLocation = administrative_district ? administrative_district.attributes.location : ""
	const districtMail = administrative_district ? administrative_district.attributes.email : ""
	const districtPhone1 = administrative_district ? administrative_district.attributes.phone1 : ""
	const socialWorkerStreet = socialWorker ? socialWorker.attributes.street : ""
	const socialWorkerPlz = socialWorker ? socialWorker.attributes.plz : ""
	const socialWorkerLocation = socialWorker ? socialWorker.attributes.location : ""
	const socialWorkerMail = socialWorker ? socialWorker.attributes.email : ""
	const socialWorkerPhone1 = socialWorker ? socialWorker.attributes.phone1 : ""
	const schoolName = school ? school.attributes.name : ""
	const schoolStreet = school ? school.attributes.street : ""
	const schoolPlz = school ? school.attributes.plz : ""
	const schoolLocation = school ? school.attributes.location : ""
	const schoolMail = school ? school.attributes.email : ""
	const schoolPhone1 = school ? school.attributes.phone1 : ""
	const groupName = group ? group.attributes.group_name : ""

	const personData = []
	if (persons.length > 0) {
		let index = 0
		for (const person of persons) {
			//highlightedLines.push(7+personData.length)
			const plz = person.attributes.plz ? person.attributes.plz : ''
			const location = person.attributes.location ? person.attributes.location : ''
			// personData.push([person.attributes.type, ' '])
			personData.push([person.attributes.type, `${person.attributes.last_name}, ${person.attributes.first_name}`])
			personData.push(['Straße', person.attributes.street])
			personData.push(['PLZ, Ort', getPlzLocation(plz, location)])
			personData.push(['E-Mail', person.attributes.email])
			personData.push(['Telefon 1', person.attributes.phone1])
			personData.push(['Info 1', person.attributes.info1])
			personData.push(['Telefon 2', person.attributes.phone2])
			personData.push(['Info 2', person.attributes.info2])
			personData.push(['Sorgerecht', custody[index] ? 'Ja' : 'Nein'])
			index++
		}
		let lastindex = highlightedLines[highlightedLines.length - 1]
		// highlightedLines.push(lastindex + 10)
		// highlightedLines.push(lastindex + 15)
		// highlightedLines.push(lastindex + 20)
		// highlightedLines.push(lastindex + 25)
		// highlightedLines.push(lastindex + 26)
	} else {
		// highlightedLines.push(0)
		// highlightedLines.push(7)
		// highlightedLines.push(12)
		// highlightedLines.push(17)
		// highlightedLines.push(22)
		// highlightedLines.push(23)
	}

	const socialWorkerData = socialWorker ? `${socialWorker.attributes.last_name}, ${socialWorker.attributes.first_name}` : ""
	const contactUserName = contactUser ? `${contactUser.attributes.last_name}, ${contactUser.attributes.first_name}` : ""

	let dataArray = [
		['Name, Vorname', clientName],
		['Geburtsdatum', birthDate],
		['Geburtsort', birthLocation],
		['gesetzl. Grundlage', statBasis],
		['Tag der Aufnahme', entryDate],
		['Bewilligung bis', licenseDate],
		['Austritt am', exitDate],
		...personData,
		["Jugendamt", district],
		["Straße", districtStreet],
		["PLZ, Ort", getPlzLocation(districtPlz, districtLocation)],
		["E-Mail", `${districtMail}`],
		["Telefon", districtPhone1],
		["Sozialarbeiter", socialWorkerData],
		["Straße", socialWorkerStreet],
		["PLZ, Ort", getPlzLocation(socialWorkerPlz, socialWorkerLocation)],
		["E-Mail", `${socialWorkerMail}`],
		["Telefon", socialWorkerPhone1],
		["Schule/Kita", `${schoolName}`],
		["Straße", `${schoolStreet}`],
		["PLZ, Ort", getPlzLocation(schoolPlz, schoolLocation)],
		["E-Mail", `${schoolMail}`],
		["Telefon", `${schoolPhone1}`],
		["Gruppe", `${groupName}`],
		["Kontakterzieher", contactUserName],
	];
	//Filtere leere oder undefined Einträge heraus
	let body = [];
	let entry_number = -1
	dataArray.forEach((entry) => {
		if (entry[1] && entry[1] != 'undefined') {
			body.push([entry[0], entry[1]])
			entry_number = entry_number + 1
		}
		if (entry[1] && (entry[0] == 'Name, Vorname' || entry[0] == 'Jugendamt' || entry[0] == 'Sozialarbeiter' || entry[0] == 'Schule/Kita'
			|| entry[0] == 'Gruppe' || entry[0] == 'Amtsvormund'
			|| entry[0] == 'Mutter' || entry[0] == 'Vater' || entry[0] == 'Oma'
			|| entry[0] == 'Opa' || entry[0] == 'Stiefvater' || entry[0] == 'Stiefmutter'
			|| entry[0] == 'Tante' || entry[0] == 'Onkel' || entry[0] == 'Bruder' 
			|| entry[0] == 'Schwester' || entry[0] == 'Schwägerin' || entry[0] == 'Schwager' 
			|| entry[0] == 'Pflegeeltern' || entry[0] == 'Spziale Eltern' || entry[0] == 'Wirtschaftliche Jugendhilfe')) {
			highlightedLines.push(entry_number)
		
		}
	})

	
	// Example usage with columnStyles,
	autoTable(doc, {
		styles: { 1: { fillColor: [255, 0, 0] } },
		columnStyles: { 0: { halign: 'center', valign: "middle", fontStyle: "bold", cellWidth: 'wrap' } }, // Cells in first column centered and green
		margin: { top: 12 },
		body: body,
		didParseCell: function (data) {
			data.cell.styles.borders = "b"; data.cell.styles.lineWidth = 0.1;
			data.cell.styles.textColor = "black";
			if (highlightedLines.includes(data.row.index)) {
				data.cell.styles.fillColor = [220, 220, 220]

			} else {
				// data.cell.styles.fillColor = [255, 255, 255]
			}
		},
		didDrawPage: function (data) {
			doc.text(`Stammdaten ${clientName}`, 15, 10);
		}
	})


	doc.save(`${clientName}.pdf`)
}
