var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel m-5",staticStyle:{"background-color":"white","color":"black"},attrs:{"id":"dataToPrint"}},[_c('div',{staticClass:"panel-heading is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between",staticStyle:{"background-color":"rgba(0, 0, 0, 0.8)","color":"white"}},[_c('p',[_vm._v("Stammdaten")]),_c('b-button',{attrs:{"icon-left":"download","type":"is-primary","id":"printButton"},on:{"click":_vm.printData}},[_vm._v(" Herunterladen ")])],1),_c('div',{staticClass:"py-2 is-flex is-flex-direction-column"},[(_vm.client.attributes.last_name && _vm.client.attributes.first_name)?_c('row',{attrs:{"title":"Name, Vorname","value":_vm.client.attributes.last_name +
                    ', ' +
                    _vm.client.attributes.first_name}}):_vm._e(),(_vm.client.attributes.birth_date)?_c('row',{attrs:{"title":"Geburtsdatum","value":_vm.client.attributes.birth_date.toLocaleDateString('de-DE', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })}}):_vm._e(),(_vm.client.attributes.birth_location)?_c('row',{attrs:{"title":"Geburtsort","value":_vm.client.attributes.birth_location}}):_vm._e(),(_vm.client.attributes.statutory_basis)?_c('row',{attrs:{"title":"gesetzl. Grundlage","value":_vm.client.attributes.statutory_basis.attributes.name}}):_vm._e(),(_vm.client.attributes.entry)?_c('row',{attrs:{"title":"Tag der Aufnahme","value":_vm.client.attributes.entry.toLocaleDateString('de-DE', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })}}):_vm._e(),(_vm.client.attributes.license)?_c('row',{attrs:{"title":"Bewilligung bis","value":_vm.client.attributes.license.toLocaleDateString('de-DE', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })}}):_vm._e(),(_vm.client.attributes.exit)?_c('row',{attrs:{"title":"Austritt am","value":_vm.client.attributes.exit.toLocaleDateString('de-DE', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })}}):_vm._e(),_vm._l((_vm.client.attributes.persons),function(person,index){return _c('div',{key:index},[_c('row',{staticStyle:{"background-color":"whitesmoke"},attrs:{"title":person.attributes.type,"value":''}}),(person.attributes.last_name && person.attributes.first_name)?_c('row',{attrs:{"title":"Name, Vorname","value":person.attributes.last_name +
                        ', ' +
                        person.attributes.first_name}}):_vm._e(),(person.attributes.street)?_c('row',{attrs:{"title":"Straße","value":person.attributes.street}}):_vm._e(),(person.attributes.plz && person.attributes.location)?_c('row',{attrs:{"title":"PLZ, Ort","value":_vm.getPlzLocation(person.attributes.plz, person.attributes.location)}}):_vm._e(),(person.attributes.email)?_c('row',{attrs:{"title":"E-Mail","value":person.attributes.email}}):_vm._e(),(person.attributes.phone1)?_c('row',{attrs:{"title":"Telefon 1","value":person.attributes.phone1}}):_vm._e(),(person.attributes.info1)?_c('row',{attrs:{"title":"Info 1","value":person.attributes.info1}}):_vm._e(),(person.attributes.phone2)?_c('row',{attrs:{"title":"Telefon 2","value":person.attributes.phone2}}):_vm._e(),(person.attributes.info2)?_c('row',{attrs:{"title":"Info 2","value":person.attributes.info2}}):_vm._e(),(
                        _vm.client.attributes.custody &&
                        _vm.client.attributes.custody[index]
                    )?_c('row',{attrs:{"title":"Sorgerecht","value":"Ja"}}):_c('row',{attrs:{"title":"Sorgerecht","value":"Nein"}})],1)}),(_vm.client.attributes.administrative_district && _vm.client.attributes.administrative_district.attributes.name)?_c('row',{staticStyle:{"background-color":"whitesmoke"},attrs:{"title":"Jugendamt","value":_vm.client.attributes.administrative_district.attributes.name}}):_vm._e(),(_vm.client.attributes.administrative_district && _vm.client.attributes.administrative_district.attributes.street)?_c('row',{attrs:{"title":"Straße","value":_vm.client.attributes.administrative_district.attributes.street}}):_vm._e(),(_vm.client.attributes.administrative_district && _vm.client.attributes.administrative_district.attributes.plz && _vm.client.attributes.administrative_district.attributes.location)?_c('row',{attrs:{"title":"PLZ, Ort","value":_vm.getPlzLocation(_vm.client.attributes.administrative_district.attributes.plz, _vm.client.attributes.administrative_district.attributes.location)}}):_vm._e(),(_vm.client.attributes.administrative_district && _vm.client.attributes.administrative_district.attributes.email)?_c('row',{attrs:{"title":"Mail","value":_vm.client.attributes.administrative_district.attributes.email,"href":'mailto:' +
                    _vm.client.attributes.administrative_district.attributes.email}}):_vm._e(),(_vm.client.attributes.administrative_district && _vm.client.attributes.administrative_district.attributes.phone1)?_c('row',{attrs:{"title":"Telefon","href":'tel:' +
                    _vm.client.attributes.administrative_district.attributes.phone1,"value":_vm.client.attributes.administrative_district.attributes.phone1}}):_vm._e(),(_vm.client.attributes.socialWorker && _vm.client.attributes.socialWorker.attributes.last_name && _vm.client.attributes.socialWorker.attributes.first_name)?_c('row',{staticStyle:{"background-color":"whitesmoke"},attrs:{"title":"Sozialarbeiter","value":_vm.client.attributes.socialWorker.attributes.last_name +
                    ', ' +
                    _vm.client.attributes.socialWorker.attributes.first_name}}):_vm._e(),(_vm.client.attributes.socialWorker && _vm.client.attributes.socialWorker.attributes.street)?_c('row',{attrs:{"title":"Straße","value":_vm.client.attributes.socialWorker.attributes.street}}):_vm._e(),(_vm.client.attributes.socialWorker && _vm.client.attributes.socialWorker.attributes.plz && _vm.client.attributes.socialWorker.attributes.location)?_c('row',{attrs:{"title":"PLZ, Ort","value":_vm.getPlzLocation(_vm.client.attributes.socialWorker.attributes.plz, _vm.client.attributes.socialWorker.attributes.location)}}):_vm._e(),(_vm.client.attributes.socialWorker && _vm.client.attributes.socialWorker.attributes.email)?_c('row',{attrs:{"title":"Mail","value":_vm.client.attributes.socialWorker.attributes.email,"href":'mailto:' +
                    _vm.client.attributes.socialWorker.attributes.email}}):_vm._e(),(_vm.client.attributes.socialWorker && _vm.client.attributes.socialWorker.attributes.phone1)?_c('row',{attrs:{"title":"Telefon","href":'tel:' +
                    _vm.client.attributes.socialWorker.attributes.phone1,"value":_vm.client.attributes.socialWorker.attributes.phone1}}):_vm._e(),(_vm.client.attributes.school && _vm.client.attributes.school.attributes.name)?_c('row',{staticStyle:{"background-color":"whitesmoke"},attrs:{"title":"Schule/Kita","value":_vm.client.attributes.school.attributes.name}}):_vm._e(),(_vm.client.attributes.school && _vm.client.attributes.school.attributes.street)?_c('row',{attrs:{"title":"Straße","value":_vm.client.attributes.school.attributes.street}}):_vm._e(),(_vm.client.attributes.school && _vm.client.attributes.school.attributes.plz && _vm.client.attributes.school.attributes.location)?_c('row',{attrs:{"title":"PLZ, Ort","value":_vm.getPlzLocation(_vm.client.attributes.school.attributes.plz, _vm.client.attributes.school.attributes.location)}}):_vm._e(),(_vm.client.attributes.school && _vm.client.attributes.school.attributes.email)?_c('row',{attrs:{"title":"Mail","value":_vm.client.attributes.school.attributes.email,"href":'mailto:' + _vm.client.attributes.school.attributes.email}}):_vm._e(),(_vm.client.attributes.school && _vm.client.attributes.school.attributes.phone1)?_c('row',{attrs:{"title":"Telefon","href":'tel:' + _vm.client.attributes.school.attributes.phone1,"value":_vm.client.attributes.school.attributes.phone1}}):_vm._e(),(_vm.client.attributes.group.attributes.group_name)?_c('row',{staticStyle:{"background-color":"whitesmoke"},attrs:{"title":"Gruppe","value":_vm.client.attributes.group.attributes.group_name}}):_vm._e(),(_vm.contactUser)?_c('row',{attrs:{"title":"Kontakterzieher","value":_vm.contactUser.attributes.last_name +
                    ', ' +
                    _vm.contactUser.attributes.first_name}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }