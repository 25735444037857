<template>
    <div
        class="panel m-5"
        style="background-color: white; color: black"
        id="dataToPrint"
    >
        <div
            class="
                panel-heading
                is-flex
                is-flex-direction-row
                is-align-items-center
                is-justify-content-space-between
            "
            style="background-color: rgba(0, 0, 0, 0.8); color: white"
        >
            <p>Stammdaten</p>
            <b-button
                icon-left="download"
                @click="printData"
                type="is-primary"
                id="printButton"
            >
			Herunterladen
            </b-button>
        </div>
        <div class="py-2 is-flex is-flex-direction-column">
            <row v-if="client.attributes.last_name && client.attributes.first_name"
                title="Name, Vorname"
                :value="
                    client.attributes.last_name +
                    ', ' +
                    client.attributes.first_name
                "
            />
            <row
                title="Geburtsdatum"
				v-if="client.attributes.birth_date"
                :value="
                    client.attributes.birth_date.toLocaleDateString('de-DE', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })
                "
            />
            <row v-if="client.attributes.birth_location" title="Geburtsort" :value="client.attributes.birth_location" />
			<row
				v-if="client.attributes.statutory_basis"
				title="gesetzl. Grundlage"
				:value="client.attributes.statutory_basis.attributes.name"
			/>
			<row
                title="Tag der Aufnahme"
				v-if="client.attributes.entry"
                :value="
                    client.attributes.entry.toLocaleDateString('de-DE', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })
                "
            />
            <row
                title="Bewilligung bis"
				v-if="client.attributes.license"
                :value="
                    client.attributes.license.toLocaleDateString('de-DE', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })
                "
            />
			<row
				title="Austritt am"
				v-if="client.attributes.exit"
				:value="
                    client.attributes.exit.toLocaleDateString('de-DE', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })
                "
			/>
            <div
                v-for="(person, index) in client.attributes.persons"
                :key="index"
            >
                <row
                    :title="person.attributes.type"
                    :value="''"
                    style="background-color: whitesmoke"
                />
                <row v-if="person.attributes.last_name && person.attributes.first_name"
                    title="Name, Vorname"
                    :value="
                        person.attributes.last_name +
                        ', ' +
                        person.attributes.first_name
                    "
                />
                <row v-if="person.attributes.street" title="Straße" :value="person.attributes.street" />
                <row
					v-if="person.attributes.plz && person.attributes.location"
                    title="PLZ, Ort"
                    :value="getPlzLocation(person.attributes.plz, person.attributes.location)"
                />
                <row v-if="person.attributes.email" title="E-Mail" :value="person.attributes.email" />
                <row v-if="person.attributes.phone1" title="Telefon 1" :value="person.attributes.phone1" />
                <row v-if="person.attributes.info1" title="Info 1" :value="person.attributes.info1" />
                <row v-if="person.attributes.phone2" title="Telefon 2" :value="person.attributes.phone2" />
                <row v-if="person.attributes.info2" title="Info 2" :value="person.attributes.info2" />
                <row
                    title="Sorgerecht"
                    v-if="
                        client.attributes.custody &&
                        client.attributes.custody[index]
                    "
                    value="Ja"
                />
                <row title="Sorgerecht" v-else value="Nein" />

            </div>
            <row
				v-if="client.attributes.administrative_district && client.attributes.administrative_district.attributes.name"
                title="Jugendamt"
				style="background-color: whitesmoke"
                :value="
                    client.attributes.administrative_district.attributes.name
                "
            />
            <row
				v-if="client.attributes.administrative_district && client.attributes.administrative_district.attributes.street"
                title="Straße"
                :value="
                    client.attributes.administrative_district.attributes.street
                "
            />
            <row
				v-if="client.attributes.administrative_district && client.attributes.administrative_district.attributes.plz && client.attributes.administrative_district.attributes.location"
                title="PLZ, Ort"
                :value="getPlzLocation(client.attributes.administrative_district.attributes.plz, client.attributes.administrative_district.attributes.location)"
            />
            <row
				v-if="client.attributes.administrative_district && client.attributes.administrative_district.attributes.email"
                title="Mail"
                :value="
                    client.attributes.administrative_district.attributes.email
                "
                :href="
                    'mailto:' +
                    client.attributes.administrative_district.attributes.email
                "
            />
            <row
				v-if="client.attributes.administrative_district && client.attributes.administrative_district.attributes.phone1"
                title="Telefon"
                :href="
                    'tel:' +
                    client.attributes.administrative_district.attributes.phone1
                "
                :value="
                    client.attributes.administrative_district.attributes.phone1
                "
            />
			<row
				v-if="client.attributes.socialWorker && client.attributes.socialWorker.attributes.last_name && client.attributes.socialWorker.attributes.first_name"
                title="Sozialarbeiter"
				style="background-color: whitesmoke"
				:value="
                    client.attributes.socialWorker.attributes.last_name +
                    ', ' +
                    client.attributes.socialWorker.attributes.first_name
                "
            />
			<row
				v-if="client.attributes.socialWorker && client.attributes.socialWorker.attributes.street"
                title="Straße"
                :value="
                    client.attributes.socialWorker.attributes.street
                "
            />
            <row
				v-if="client.attributes.socialWorker && client.attributes.socialWorker.attributes.plz && client.attributes.socialWorker.attributes.location"
                title="PLZ, Ort"
                :value="getPlzLocation(client.attributes.socialWorker.attributes.plz, client.attributes.socialWorker.attributes.location)"
            />
            <row
				v-if="client.attributes.socialWorker && client.attributes.socialWorker.attributes.email"
                title="Mail"
                :value="
                    client.attributes.socialWorker.attributes.email
                "
                :href="
                    'mailto:' +
                    client.attributes.socialWorker.attributes.email
                "
            />
            <row
				v-if="client.attributes.socialWorker && client.attributes.socialWorker.attributes.phone1"
                title="Telefon"
                :href="
                    'tel:' +
                    client.attributes.socialWorker.attributes.phone1
                "
                :value="
                    client.attributes.socialWorker.attributes.phone1
                "
            />
            <row
				v-if="client.attributes.school && client.attributes.school.attributes.name"
                title="Schule/Kita"
				style="background-color: whitesmoke"
                :value="client.attributes.school.attributes.name"
            />
            <row
				v-if="client.attributes.school && client.attributes.school.attributes.street"
                title="Straße"
                :value="client.attributes.school.attributes.street"
            />
            <row
				v-if="client.attributes.school && client.attributes.school.attributes.plz && client.attributes.school.attributes.location"  
                title="PLZ, Ort"
                :value="getPlzLocation(client.attributes.school.attributes.plz, client.attributes.school.attributes.location)
                "
            />
            <row
				v-if="client.attributes.school && client.attributes.school.attributes.email"
                title="Mail"
                :value="client.attributes.school.attributes.email"
                :href="'mailto:' + client.attributes.school.attributes.email"
            />
            <row
				v-if="client.attributes.school && client.attributes.school.attributes.phone1"
                title="Telefon"
                :href="'tel:' + client.attributes.school.attributes.phone1"
                :value="client.attributes.school.attributes.phone1"
            />
            <row v-if="client.attributes.group.attributes.group_name"
                title="Gruppe"
				style="background-color: whitesmoke"
                :value="client.attributes.group.attributes.group_name"
            />
            <row
				v-if="contactUser"
                title="Kontakterzieher"
                :value="
                    contactUser.attributes.last_name +
                    ', ' +
                    contactUser.attributes.first_name
                "
            />
        </div>
    </div>
</template>

<script>
import Row from "@/components/helper/Row";
import { mapGetters } from "vuex";
import jsPDF from "jspdf"
import {printClientPdf} from "@/lib/pdfPrinter/ClientPrinter";
import {getPlzLocation} from "@/lib/pdfPrinter/UserPdf";

export default {
    name: "ClientTable",
    components: { Row },
    props: ["client"],
    computed: {
        ...mapGetters({
            usermetas: "usermetas/getUserMetas",
			userName: "usermetas/getUserFullName"
        }),
        contactUser() {
            if (this.usermetas) {
                for (const user of this.usermetas) {
                    if (
                        user.attributes.clients.filter((client) => {
                            return client.id === this.client.id;
                        }).length > 0
                    ) {
                        return user;
                    }
                }
            }
            return undefined;
        },
    },
    methods: {
        async printData() {
			printClientPdf(this.client, this.contactUser)
        },
		getPlzLocation(plz, location){
			return getPlzLocation(plz, location)
		}
    },
};
</script>

<style scoped>
hr {
    margin: 0.25rem !important;
}
</style>
