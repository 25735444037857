import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../../errorhandling';

let localSubscription = undefined;

// Client subscription
function createSubscription(store) {
	let clientQuery = new Parse.Query('Client');
	clientQuery.notEqualTo("deleted", true)
	clientQuery.include("socialWorker")
	clientQuery.include("persons")
	clientQuery.include("administrative_district")
	clientQuery.notEqualTo("inactive", true)
	clientQuery.addAscending("last_name")
		.limit(1000)
		.find()
		.then((results) => {
			store.dispatch('clients/set', results)
		})
		.catch((error) => {
			handleParseError(error);
		
		});
	clientQuery.subscribe().then((subscription) => {
		
		localSubscription = subscription;
		subscription.on('create', (object) => {
			
			store.dispatch('clients/insert', object)
		});
		subscription.on('update', (object) => {
		
			store.dispatch('clients/replace', object)
		});
		subscription.on('enter', (object) => {
			
			store.dispatch('clients/insert', object)
		});
		subscription.on('leave', (object) => {
			
			store.dispatch('clients/delete', object)
		});
		subscription.on('delete', (object) => {
			
			store.dispatch('clients/delete', object)
		});
		subscription.on('close', () => {
			
		});
	})
}

export default function createClientSubscription() {
	return store => {
		//Erzeuge einmalig eine neue Subscription für den Datensatz. 
		//Fall "Bereits in der Anwendung"
		if(store.state.currentUser && !localSubscription) {
			createSubscription(store)
		}
		//Erzeuge einmalig eine neue Subscription für den Datensatz. 
		//Fall "Normaler Login"
		store.subscribe(mutation => {
			if (mutation.type === 'SET_CURRENT_USER') {
				if (!localSubscription) {
					createSubscription(store)
				}
			}
		})
	}
}